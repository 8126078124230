/**
 * Type: ページ
 * What: タッチダイナソー
 */
import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { scroller } from 'react-scroll';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Button } from '../components/Btn';
import Image from '../util/Image';
import BreadcrumbComponent from '../components/Breadcrumb';
import SimpleSwiper from '../components/Slider.fade';
import {DINOSAUR_CONNER, DINOSAUR_SET, DINOSAUR_GOODS, DINOSAUR_EAT, DINOSAUR_MAP} from '../config/Dinosaur';
import ContentsListUnko from '../components/ContentsListUnko';
import PostListComponent from '../components/PostList/PostList.dinosaur';
import TimerFunc from '../util/Timer';
import mainImg from '../images/dinosaur/main-dinosaur.jpg';
import ContentsListDinosaur from '../components/ContentsListDinosaur';
import useMediaQuery from '../util/useMediaQuery';


const pageTitle = '昭和建設 presents タッチ ザ ダイナソー 恐竜アドベンチャー展';
const pageDescription = '2022年7月9日(土)～9月11日(日)の期間、BOSS E・ZO FUKUOKA 6Fイベントホールにて、「昭和建設 presents タッチ ザ ダイナソー 恐竜アドベンチャー展」を開催！';
const pageSlug = 'dinosaur';

export const query = graphql`
  {
    site {
      siteMetadata {
        uuidEvent
        ticket
        ticketReady
      }
    }
  }
`;

const mq = useMediaQuery;


const sliderDataobj = [
  {
    filename: mainImg,
    noAnimation: true,
  },
];

const scrollFunc = (porps,Offset) => {
  scroller.scrollTo(porps, {
    duration: 800,
    delay: 0,
    offset: Offset,
    smooth: 'easeOutQuint',
  });

};

// Data Props Template
const Template = ({ data }) => {
  const url = data.site.siteMetadata;

  const [isModal, setIsModal] = useState(-1);
  const [Offset, setOffset] = useState(false);
  const scrollOffset = mq("pc") ? -100 : -60;

  useEffect(() => {
    setOffset(scrollOffset);
  },[scrollOffset]);

  // モーダル展開
  const onClickModal = (e) => {
    const num = Number(e.currentTarget.dataset.modal);
    setIsModal(num);
  };

  // モーダル閉じる
  const onCloseModal = () => {
    setIsModal(-1);
  };

  // モーダルコンテンツ
  const ModalContent = () => {
    const name = DINOSAUR_CONNER[isModal] && DINOSAUR_CONNER[isModal].name;
    const detail = DINOSAUR_CONNER[isModal] && DINOSAUR_CONNER[isModal].detail;
    const img = DINOSAUR_CONNER[isModal] && DINOSAUR_CONNER[isModal].img;
    const colorClass = DINOSAUR_CONNER[isModal] && DINOSAUR_CONNER[isModal].colorClass;
    const imgSlider = DINOSAUR_CONNER[isModal] && DINOSAUR_CONNER[isModal].imgSlider;
    const sliderImg = DINOSAUR_CONNER[isModal] && DINOSAUR_CONNER[isModal].sliderImg;
    return (
      <div className={`modal e-modal ${isModal > -1 ? 'is-active' : ''}`}>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="modal-background" onClick={onCloseModal} />
        <button
          type="button"
          className="delete"
          aria-label="close"
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease-out-expo"
          onClick={onCloseModal}
        />
        <div className={`modal-content c-${colorClass}`}>
          <div className="e-modal-image">
            <figure className="image">
            {imgSlider ? (
              <SimpleSwiper data={sliderImg} />
            ) : (
              <Image filename={img || 'now-printing.jpg'} />
            )}
            </figure>
          </div>
          <div className="e-modal-content">
            <h3 className="ttl c-4">{name}</h3>
            <p
              dangerouslySetInnerHTML={{ __html: detail }}
            />
          </div>
        </div>
      </div>
    );
  };
  const [fetchReady, setFetchReady] = useState(false);

  useEffect(() => {
    setFetchReady(true);
  }, []);

  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} description={pageDescription} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="pageMain bg-12 bs-4">
        <div className="kv-wrap">
          <div className='mt-3 mb-3 sp-mb0 border-box-red sub-desc'>
            <p className="text is-3 tac fwb">本イベントは終了しました。<br />たくさんのご来場ありがとうございました。</p>
          </div>
          <div className="kv-inner">
            <SimpleSwiper data={sliderDataobj} />
          </div>
          <div className="container">
            <div className="inner tac">
              {url.ticketReady === '1' ? (
                <div
                  className="btn-wrap mb-0"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <Button
                    className="btn-buy"
                    href="https://ticket.e-zofukuoka.com/#/order?id=X3JE53RECRY69W28OMVUAXTRJP4PY5MSSTC2CJSWZVHC7X9BWY56SCK3MCL41V1Z"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    チケットを購入する
                  </Button>
                </div>
              ) : null}
              <div className="btn-group mt-5">
                <Link
                  to="/news/info/20230311252/"
                  className="btn-def caution-ticket-btn narrow"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <span>新型コロナウイルス感染防止の対策について</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section sc-topics pb-0 pc-mb20">
        <div className="container mb0">
          <div className="inner mb0">
            <p className="has-text-align-center has-text-weight-bold mb-3 text is-3 is-3-sp">
              7月9日（土）～9月11日（日）の期間、<br className="sp" />BOSS E・ZO FUKUOKA 6Fイベントホールにて、「昭和建設 presents タッチ ザ ダイナソー 恐竜アドベンチャー展」を開催！
            </p>
            <p className="has-text-align-center has-text-weight-bold mb-3 text is-3 is-3-sp">
              巨大な恐竜が生息する迷路から、無事脱出できるのか？！貴重な実物化石の展示や化石の発掘体験も。夏休みはみんなで恐竜の世界に飛び込もう！
            </p>
          </div>
        </div>
      </section>
      <PostListComponent />
      <section className="section sc-topics bs-topics">
        <div className="container">
          <div className="inner mb0">
            <div className="sc-columns now-event mb-6">
              {DINOSAUR_MAP.map((categories, index) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className={`col big ${categories.colorClass}`}
                  data-modal={index}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <ContentsListDinosaur {...categories} />
                </div>
              ))}
            </div>
            <h2 className="headline mb20">
              <span>迷路エリア紹介</span>
            </h2>
            <p className='has-text-align-center has-text-weight-bold sp-mb10 mb60 sp-mb30 text is-3 is-3-sp'>
              迷路で巨大恐竜と遭遇！？<br />恐竜探検迷路「ダイナソーアイランド ベースキャンプ」
            </p>
            <div className="sc-columns now-event mb60 sp-mb30 ">
              <video muted playsInline controls>
                <source src="/files/dinosaur.mp4" type="video/mp4" />
              </video>
            </div>
            <p className="has-text-align-center sp-mb10 mb30 sp-mb30 text is-3 is-3-sp">
              時空を超えて現代に突然現れた恐竜たちを保護するための無人島「ダイナソーアイランド」。<br />暗闇の中を懐中電灯で照らしながら、迷路のような島を探索し、<br />潜んでいる恐竜たちに気をつけつつ、行方不明になってしまったトリケラトプスの赤ちゃんを見つけ出そう！
            </p>
          </div>
        </div>
      </section>
      <section className="section sc-topics bs-topics">
        <div className="container">
          <div className="inner mb0">
            <h2 className="headline mb20">
              <span>展示コーナー紹介</span>
            </h2>
            <div className="sc-columns now-event">
              {DINOSAUR_CONNER.map((categories, index) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className={`col col-4 ${categories.colorClass}`}
                  key={categories.name}
                  data-modal={index}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  onClick={(e) => onClickModal(e)}
                >
                  <ContentsListUnko {...categories} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="section sc-topics bs-topics" id="setTicket">
        <div className="container">
          <div className="inner mb0">
            <h2 className="headline">
              <span>グッズ販売も充実！</span>
            </h2>
            <p className="has-text-align-center has-text-weight-bold sp-mb10 mb60 sp-mb30 text is-3 is-3-sp">
              イベント期間中、170点もの恐竜グッズを販売！<br />リアルなフィギュアからかわいいキーホルダーをはじめ、<br />お菓子や文房具、Tシャツ・帽子など、幅広いラインアップを取りそろえております。
            </p>
            <div className="sc-columns now-event  mb60 sp-mb30 ">
            {DINOSAUR_GOODS.map((categories, index) => (
                <div
                  className={`col col-2 ${categories.colorClass}`}
                  key={categories.name}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <ContentsListDinosaur {...categories} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="inner mb0">
            <h2 className="headline">
              <span>3F飲食店で<br className="sp" />恐竜アドベンチャー展<br />コラボグルメが登場！</span>
            </h2>
            <div className="sc-columns now-event  mb60 sp-mb30 ">
              {DINOSAUR_EAT.map((categories, index) => (
                <div
                  className={`col big ${categories.colorClass}`}
                  key={categories.name}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <ContentsListDinosaur {...categories} />
                </div>
              ))}

            </div>
            <p className="has-text-align-center sp-mb10 mb30 sp-mb30 text is-3 is-3-sp">
              3F飲食店では、恐竜アドベンチャー展実施期間限定のコラボフード＆ドリンクが登場します！<br className="sp" />お子さまも大人も一緒に楽しめるグルメとなっていますので、ご家族で是非ご賞味ください☆
            </p>
            <p className="has-text-align-center has-text-weight-bold sp-mb10 mb40 sp-mb30 text is-3 is-3-sp">
              販売期間：7月9日（土）～9月11日（日）
            </p>
            <div
              className="btn-wrap mb-0"
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
            >
              <Button
                className="btn-regular"
                href="https://e-zofukuoka.com/news/mlbcafe-fukuoka/202207079675/?_ga=2.60889640.587820901.1657502759-113245757.1649220435#MLB_kyoryu"
                target="_blank"
                rel="noopener noreferrer"
              >
                詳しくはこちら
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section className="section" id="unkoGoods">
        <div className="inner-slim">
          <div className="table-base">
            <table>
              <tbody>
                <tr>
                  <th>入場料金</th>
                  <td>
                    <p>
                      一般（高校生以上）：1,600円（税込）
                      <br />
                      こども（小学生以上）：900円（税込）
                      <br />
                      幼児（3歳～未就学児）：500円（税込）
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4 className="title is-5">注意事項</h4>
          <article>
            <ul className="list-base">
              <li>当館ご利用の際のお怪我や損失・損害、その他トラブルなどにつきまして当館では一切責任を負いません。</li>
              <li><b>9才以下のお子様は、保護者の同伴が必要です。</b></li>
              <li>ご入場されるお客さまはマスクの常時着用をお願いします。</li>
              <li>入場口にてお客様の検温を実施します。37.5度以上の発熱、強いだるさ（倦怠感）をはじめとする、体調不良のお客さまはご入場いただけません。</li>
              <li><b>ベビーカー・車イスでは通りづらいエリアがございます。</b>ベビーカーは3Fベビーカー置き場をご利用ください。</li>
              <li>営業中止を除き、いかなる場合もチケットの払い戻しは行っておりません。</li>
              <li>チケットの転売は禁止しており、転売にて購入いただいたチケットではご入場頂けず、その場合も当館は一切の責任を負いません。</li>
              <li>お子様連れのお客様は、保護者様の監督責任のもと安全にお過ごしください。</li>
              <li>イベントスペースでの喫煙、アメ・ガムを含む飲食はお断りしております。</li>
              <li><b>会場内にトイレ・休憩室はございません</b></li>
              <li>体調不良・泥酔状態でのご入場はお断りしております。</li>
              <li>全長50cm以上の荷物は1Fもしくは3Fのコインロッカー、荷物置場をご利用ください</li>
              <li>本イベント会場内は、補助犬を含み動物を連れての入場はお断りしております。</li>
              <li>事前承諾のない商業目的の撮影や取材はお断りしております。</li>
            </ul>
            <h4 className="ttl-strip">持ち込み禁止物</h4>
            <p>
              危険物、臭気物、生花など
              <br />
              ペットボトル、水筒以外の蓋が無いドリンク類
              <br />
              その他、スタッフが危険であると判断した物品
            </p>
          </article>
          <article>
            <h4 className="ttl-strip">障がい者割引について</h4>
            <p>
              障がい者割引チケットをご購入のお客様は、該当のチケットを購入後、入場口で障がい者手帳をご提示ください。<br />
              ※身体障害者手帳、療育手帳、精神障害者手帳保健福祉手帳の3つが対象です。<br />
              ※交付を受けている方本人のみ割引適用となります。
            </p>
          </article>
        </div>
      </section>

      <ModalContent />
    </Layout>
  );
};

export default Template;
