const SLIDER_CONNER01 = [
  {
    filename: `img-dinosaur-1.jpg`,
  },
];
const SLIDER_CONNER02 = [
  {
    filename: `img-dinosaur-2.jpg`,
  },
];
const SLIDER_CONNER03 = [
  {
    filename: `img-dinosaur-3.jpg`,
  },
];
const SLIDER_CONNER04 = [
  {
    filename: `img-dinosaur-4.jpg`,
  },
];
const SLIDER_CONNER05 = [
  {
    filename: `img-dinosaur-5.jpg`,
  },
];
const SLIDER_CONNER06 = [
  {
    filename: `img-dinosaur-6.jpg`,
  },
];
const SLIDER_CONNER07 = [
  {
    filename: `img-dinosaur-7.jpg`,
  },
];
const SLIDER_CONNER08 = [
  {
    filename: `img-dinosaur-8.jpg`,
  },
];
const SLIDER_CONNER09 = [
  {
    filename: `img-dinosaur-9.jpg`,
  },
];
const SLIDER_SET = [
  {
    filename: `img-dinosaur-set.jpg`,
  },
];
const SLIDER_SET2 = [
  {
    filename: `img-dinosaur-set2-1.jpg`,
  },
  {
    filename: `img-dinosaur-set2-2.jpg`,
  },
];
const SLIDER_EAT = [
  {
    filename: `iimg-dinosaur-gourmand.jpg`,
  },
];
const SLIDER_GOODS1 = [
  {
    filename: `iimg-dinosaur-goods-1.jpg`,
  },
];
const SLIDER_GOODS2 = [
  {
    filename: `iimg-dinosaur-goods-2.jpg`,
  },
];

export const DINOSAUR_CONNER = [
  {
    categories: 'team-lab', // カテゴリー名
    name: 'ティラノサウルスがお出迎え！', // エリア名
    detail: '<p>入場すると、迫力のあるティラノサウルスがみんなをお出迎え！ここで記念写真を撮ろう！</p>', // 説明
    img: 'img-dinosaur-1.jpg', // 画像名
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    imgSlider: false,
    sliderImg: SLIDER_CONNER01,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: '化石の発掘を体験！', // エリア名
    detail: '<p>埋まった恐竜化石の発掘体験をしよう♪実際の発掘でも使われる刷毛を使って、化石発掘の疑似体験ができます。</p>', // 説明
    img: 'img-dinosaur-3.jpg', // 画像名
    imgSlider: false,
    sliderImg: SLIDER_CONNER03,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'ティラノサウルスの半身骨格！', // エリア名
    detail: '<p>最大級の肉食恐竜で、世界で最も優美とされるティラノサウルス「スタン」の半身骨格を展示。大迫力かつ優美なティラノサウルス「スタン」の姿をお楽しみください。その他にも2つの全身骨格を展示しています。</p>', // 説明
    img: 'img-dinosaur-4.jpg', // 画像名
    imgSlider: false,
    sliderImg: SLIDER_CONNER04,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: '戦いの様子が残った奇跡の化石！', // エリア名
    detail: '<p>モンゴルで発見された、プロトケラトプスとヴェロキラプトルの戦い様子がそのまま残った奇跡の化石を大公開！ 恐竜同士が戦う姿が残った貴重な化石をぜひご覧ください。</p>', // 説明
    img: 'img-dinosaur-5.jpg', // 画像名
    imgSlider: false,
    sliderImg: SLIDER_CONNER05,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: '発掘について学ぼう！', // エリア名
    detail: '<p>発掘道具や化石発掘の心得などを分かりやすく展示！</p>', // 説明
    img: 'img-dinosaur-6.jpg', // 画像名
    imgSlider: false,
    sliderImg: SLIDER_CONNER06,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: '本物の化石を触ってみよう！', // エリア名
    detail: '<p>ティラノサウルスと同じくらいの大きさのスピノサウルスやモササウルスの本物の歯の化石を展示！ハドロサウルスのあごや、エドモントサウルスの足指の骨にも触ってみよう。</p>', // 説明
    img: 'img-dinosaur-7.jpg', // 画像名
    imgSlider: false,
    sliderImg: SLIDER_CONNER07,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: '大きな化石に触れるタッチステーション！', // エリア名
    detail: '<p>アンモナイトやトリケラトプスのツノなど、計5点をずらりと展示！</p>', // 説明
    img: 'img-dinosaur-8.jpg', // 画像名
    imgSlider: false,
    sliderImg: SLIDER_CONNER08,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: '実物大の模型に触れる！', // エリア名
    detail: '<p>体長22m以上もあったといわれるアパトサウルスの大腿骨と実物大の前足の模型を展示。前足の模型は登ったり座ったりすることができます！アパトサウルスの大きさを肌で感じよう！</p>', // 説明
    img: 'img-dinosaur-9.jpg', // 画像名
    imgSlider: false,
    sliderImg: SLIDER_CONNER09,
  },
];
export const DINOSAUR_SET = [
  {
    categories: 'team-lab', // カテゴリー名
    img: 'img-dinosaur-set.jpg', // 画像名
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    imgSlider: true,
    sliderImg: SLIDER_SET2,
    noPointer: true,
    descWrap: true,
    subName: '「昭和建設 presents タッチ ザ ダイナソー 恐竜アドベンチャー展」&「王貞治ベースボールミュージアム」セット券',
    name: '夏休みわくわく学習セット',
    description2 :true,
    ticketUrl: 'https://ticket.e-zofukuoka.com/#/order?id=SFTO6Y2DV5ZWSDTDPRBXI0Q3TEE51H1YTYJDXH67VG02BU7QHNHLZRHHELBVWILR',
  },
];
export const DINOSAUR_MAP = [
  {
    categories: 'team-lab', // カテゴリー名
    img: 'img-dinosaur-map.jpg', // 画像名
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    imgSlider: false,
    noPointer: true,
    descWrap: false,
  },
];
export const DINOSAUR_GOODS = [
  {
    categories: 'team-lab', // カテゴリー名
    img: 'img-dinosaur-goods-1.jpg', // 画像名
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    imgSlider: false,
    sliderImg: SLIDER_GOODS1,
    noPointer: true,
  },
  {
    categories: 'team-lab', // カテゴリー名
    img: 'img-dinosaur-goods-2.jpg', // 画像名
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    imgSlider: false,
    sliderImg: SLIDER_GOODS2,
    noPointer: true,
  },
];
export const DINOSAUR_EAT = [
  {
    categories: 'team-lab', // カテゴリー名
    img: 'img-dinosaur-gourmand.jpg', // 画像名
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    imgSlider: false,
    sliderImg: SLIDER_EAT,
    noPointer: true,
    descWrap: false,
    // description :'<p>3F飲食店では、恐竜アドベンチャー展実施期間限定のコラボフード＆ドリンクが登場します！お子さまも大人も一緒に楽しめるグルメとなっていますので、ご家族で是非ご賞味ください☆</p><p>販売期間：7月9日（土）～9月11日（日）</p>',
    // ticketUrl2: 'https://e-zofukuoka.com/news/mlbcafe-fukuoka/202207079675/?_ga=2.60889640.587820901.1657502759-113245757.1649220435#MLB_kyoryu',
  },
];
