/**
 * Type: コンポーネント
 * What: コンテンツリスト
 */
import React from 'react';
import Image from '../util/Image';
import { Button } from './Btn';
import SimpleSwiper from './Slider.fade';

export const List = (props) => (
  <div className="col-inner">
    <div className="col-image">
      <figure className="image">
        {props.imgSlider ? (
          <SimpleSwiper data={props.sliderImg} />
        ) : (
          <Image filename={props.img ? props.img : 'now-printing.jpg'} />
        )}
      </figure>
    </div>
    {props.descWrap && (
      <div className="col-box">
        <div className={`col-content ${props.tac ? 'has-text-align-center' : ''}`}>
          <h3 className={`ttl ${props.subName && 'mb0'}`}>
            {props.name}
          </h3>
          {props.subName && (
            <h3 className="ttl">
              {props.subName}
            </h3>
          )}
          {props.description1 && (
            <>
              <p className="mt-5 mb-2">それぞれ購入するよりも、200円お得にお楽しみいただけます。</p>
              <p>大人（高校生以上）：1,900円</p>
              <p>こども（小学生以上）：1,200円</p>
              <p>幼児（3歳～未就学児）：800円</p>
            </>
          )}
          {props.description2 && (
            <>
              <p className='mt-5'>大人（高校生以上）：定価3,400円→2,900円</p>
              <p className="mb-2">小人（小学生以上）：定価1,800円→1,400円</p>
              <p>※各種割引特典のご利用はできません。</p>
              <p>※12歳以下の方のご利用は保護者の同伴が必要です（大人チケットの購入が必要）。</p>
              <p>※王貞治ベースボールミュージアムへの入館は未就学児無料のため、セットチケットの未就学児の料金設定はございません。</p>
            </>
          )}
          {props.ticketUrl && (
            <div
              className="btn-wrap mt-5"
            >
              <Button href={props.ticketUrl} className="btn-buy" target="_blank">
                セット券の購入はこちら
              </Button>
            </div>
          )}
          {props.ticketUrl2 && (
            <div
              className="btn-wrap mt-5"
            >
              <Button href={props.ticketUrl} className="btn-buy" target="_blank">
                詳しくはこちら
              </Button>
            </div>
          )}
        </div>
      </div>
    )}
  </div>
);
const ContentsListDinosaur = (props) => (
  <>
    {props.noPointer ? (
      <div className="no-pointer">
        <List {...props} />
      </div>
    ) : (
      <div className="no-link">
        <List {...props} />
      </div>
    )}
  </>
);

export default ContentsListDinosaur;
